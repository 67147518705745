export const URLS_PAGES = {
  MARKET_PLACE: {
    STAGGING: {
      HOME: 'https://marketplace.kriptonmarket.com',
    },
    PRODUCTION: {
      HOME: 'https://marketplace.kriptonmarket.com',
    },
  },
  SERVICES: {
    STAGGING: {
      HOME: 'https://staging.kriptonmarket.com',
    },
    PRODUCTION: {
      HOME: 'https://kriptonmarket.com',
    },
  },
  CASH: {
    STAGGING: {
      HOME: 'https://staging-cash.kriptonmarket.com',
    },
    PRODUCTION: {
      HOME: 'https://cash.kriptonmarket.com',
    },
  },
  LANDING: {
    STAGGING: {
      QUOTATION: 'https://staging-info.kriptonmarket.com/quotation',
    },
    PRODUCTION: {
      QUOTATION: 'https://info.kriptonmarket.com/quotation',
    },
  },
};
