import React from "react";

import { getExternalUrlPageWithEnv } from "../../utils/env";
import { toAbsoluteUrl } from "../../utils/assets";

const Header = () => (
  <header className="header">
    <div className="header-content">
      <a
        href={getExternalUrlPageWithEnv("SERVICES", "HOME")}
        className="header-logo-link"
      >
        <img
          src={toAbsoluteUrl("/assets/logo.svg")}
          alt="KM Logo"
          className="header-logo-link-img"
        />
      </a>

      <div className="header-menu-container">
        <a href="/" className="header-menu-item-link">
          <img
            src={toAbsoluteUrl("/assets/icons/marker-multi-color.svg")}
            alt="Marker"
            className="header-menu-item-img"
          />
        </a>

        <a
          href={getExternalUrlPageWithEnv("LANDING", "QUOTATION")}
          className="header-menu-item-link"
        >
          <img
            src={toAbsoluteUrl("/assets/icons/money-multi-color.svg")}
            alt="Money"
            className="header-menu-item-img"
          />
        </a>

        <a
          href={getExternalUrlPageWithEnv("MARKET_PLACE", "HOME")}
          className="header-menu-item-link"
        >
          <img
            src={toAbsoluteUrl("/assets/icons/shopping-cart-multi-color.svg")}
            alt="Shopping Cart"
            className="header-menu-item-img"
          />
        </a>

        <a
          href={getExternalUrlPageWithEnv("CASH", "HOME")}
          className="header-menu-item-link"
        >
          <img
            src={toAbsoluteUrl("/assets/icons/home-multicolor.svg")}
            alt="Home"
            className="header-menu-item-img"
          />
        </a>

        <a
          href={getExternalUrlPageWithEnv("SERVICES", "HOME")}
          className="header-menu-item-link"
        >
          <img
            src={toAbsoluteUrl("/assets/icons/world-multicolor.svg")}
            alt="Worlds"
            className="header-menu-item-img"
          />
        </a>
      </div>
    </div>
  </header>
);

export default Header;
