import React from "react";

const Spinner = ({ small = false }) => (
  <div className="spinner-wrapper">
    <div
      className={`spinner-loading ${small ? "spinner-loading-small" : ""}`}
    />
  </div>
);

export default Spinner;
