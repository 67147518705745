import React, { useMemo, memo, Fragment } from "react";

import { toAbsoluteUrl } from "../../utils/assets";
import getLabelDataBolds from "../../utils/getLabelDataBolds";

const CustomOption = ({ currentLabel, inputValue }) => {
  const labelSentences = useMemo(() => {
    const [firstSentence, ...rest] = currentLabel.split(", ");

    return {
      first: getLabelDataBolds(firstSentence, inputValue),
      rest: rest,
    };
  }, [currentLabel, inputValue]);

  return (
    <div className="search-bar-select__option-custom-container">
      <div className="search-bar-select__option-custom-text">
        <img
          src={toAbsoluteUrl("/assets/near-me.svg")}
          alt="Near Me"
          className="search-bar-select__option-custom-text-icon"
        />

        <span className="search-bar-select__option-custom-text-typography">
          {labelSentences.first.map(({ isBold, sentence }, index) =>
            isBold ? (
              <b key={`first-sentence-${index}-bold`}>{sentence}</b>
            ) : (
              <Fragment key={`first-sentence-${index}-light`}>
                {sentence}
              </Fragment>
            )
          )}

          {labelSentences.rest.map((sentence, index) => (
            <Fragment key={`rest-sentence-${index}-light`}>
              {`, ${sentence}`}
            </Fragment>
          ))}
        </span>
      </div>
    </div>
  );
};

export default memo(CustomOption);
