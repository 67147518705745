import { URLS_PAGES } from '../constants/urlsPages';

export const AWS_IDENTITY = process.env.REACT_APP_AWS_IDENTITY;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const MAP_STYLE = process.env.REACT_APP_MAP_STYLE;
export const INDEX_NAME_TO_SEARCH_PLACE =
  process.env.REACT_APP_INDEX_NAME_TO_SEARCH_PLACE;
export const APP_BASE_URL = process.env.REACT_APP_APP_BASE_URL;
export const AWS_S3_URL = process.env.REACT_APP_AWS_S3_URL;

export const getExternalUrlPageWithEnv = (page, urlType) => {
  const env = process.env.NODE_ENV === 'production' ? 'PRODUCTION' : 'STAGGING';

  return URLS_PAGES[page][env][urlType] ?? '';
}
