const buildRegexCaseInsensitive = (expression) => new RegExp(expression, "i");

const getLabelDataBolds = (currentLabel, compareVal) => {
  const currentLabelSplitted = currentLabel.split(" ");
  const compareValSplitted = compareVal.split(" ");
  const sentences = [];

  for (let index = 0; index < currentLabelSplitted.length; index++) {
    const currentWordLabel = currentLabelSplitted[index];
    const currentWordCompareVal = compareValSplitted[index];
    const isNotLastIndex = index < currentLabelSplitted.length - 1;

    const ocurrence = currentWordLabel.match(
      buildRegexCaseInsensitive(currentWordCompareVal)
    )?.[0];

    if (!currentWordCompareVal && !ocurrence) {
      sentences.push({ sentence: currentWordLabel, isBold: false });

      if (isNotLastIndex) {
        sentences.push({ sentence: " ", isBold: false });
      }

      continue;
    }

    if (ocurrence) {
      sentences.push({ sentence: ocurrence, isBold: true });
    }

    const currentRestWorld = currentWordLabel.replace(
      buildRegexCaseInsensitive(ocurrence),
      ""
    );

    if (currentRestWorld) {
      sentences.push({ sentence: currentRestWorld, isBold: false });
    }

    if (
      currentRestWorld &&
      currentWordLabel !== currentRestWorld &&
      isNotLastIndex
    ) {
      sentences.push({ sentence: " ", isBold: false });
    }

    if (!currentRestWorld && isNotLastIndex) {
      sentences.push({ sentence: " ", isBold: false });
    }
  }

  return sentences;
};

export default getLabelDataBolds;
