import axios from "axios";

import { APP_BASE_URL } from '../utils/env';

export const BASE_URL = APP_BASE_URL;

const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default API;
