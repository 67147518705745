import { useMemo } from "react";

export const useQueryParams = () => {
  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);

    return Object.fromEntries(searchParams.entries());
  }, []);

  return queryParams;
};
