import React from "react";

import Spinner from "../Spinner";
import { AWS_S3_URL } from "../../utils/env";

const Popup = ({
  loading,
  kind,
  address,
  title,
  description,
  attributes = [],
  socialNetworks = {},
  paymentUrl,
}) => {
  return (
    <div className="custom-popup-wrapper">
      {loading ? (
        <Spinner small />
      ) : (
        <>
          <div className="custom-popup-header">
            <img
              src={`${AWS_S3_URL}/img/map/icons/${kind}.svg`}
              alt={kind}
              className="custom-popup-header-img"
            />

            <div className="custom-popup-header-text-wrapper">
              <h1 className="custom-popup-header-text-title">{title}</h1>

              {description && (
                <p className="custom-popup-header-text-description">
                  {description}
                </p>
              )}
            </div>
          </div>

          <div className="custom-popup-content">
            <ul className="custom-popup-attributes-list-wrapper">
              {address && (
                <li className="custom-popup-attributes-list-item">
                  <span className="custom-popup-attributes-list-item-text">
                    {address}
                  </span>
                </li>
              )}

              {attributes.map((attr, index) => (
                <li
                  key={`attr-${index}`}
                  className="custom-popup-attributes-list-item"
                >
                  <span className="custom-popup-attributes-list-item-text">
                    {attr}
                  </span>
                </li>
              ))}

              {paymentUrl && (
                <li className="custom-popup-attributes-list-item">
                  <a
                    href={paymentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="custom-popup-attributes-list-item-link-text"
                  >
                    Link de pago
                  </a>
                </li>
              )}
            </ul>

            <div className="custom-popup-social-networks-container">
              {Object.entries(socialNetworks).reverse().map(
                ([key, value]) =>
                  value && (
                    <a
                      key={`social-network-${key}`}
                      href={value}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="custom-popup-social-networks-container-link"
                    >
                      <img
                        src={`${AWS_S3_URL}/img/map/icons/${key}.svg`}
                        alt={kind}
                        className="custom-popup-social-networks-container-img"
                      />
                    </a>
                  )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Popup;
