import React, { useCallback } from "react";
import AsyncSelect from "react-select/async";
import { useMap } from "react-map-gl";

import { INDEX_NAME_TO_SEARCH_PLACE } from "../../utils/env";
import Option from "./Option";

const SearchBar = ({ client }) => {
  const { default: currentMap } = useMap();

  const getResults = useCallback(
    async (text) => {
      if (client) {
        const { lng, lat } = currentMap.getCenter();

        const response = await client
          .searchPlaceIndexForText({
            IndexName: INDEX_NAME_TO_SEARCH_PLACE,
            BiasPosition: [lng, lat],
            Text: text,
            // FilterCountries: ['ARG'],
            MaxResults: 9,
          })
          .promise();

        const resultTransform = response.Results.map((item) => ({
          value: item.Place.Geometry.Point,
          label: item.Place.Label,
        }));

        return resultTransform;
      }
    },
    [client, currentMap]
  );

  const loadOptions = useCallback(
    async (inputValue, callback) => {
      if (inputValue) {
        const results = await getResults(inputValue);
        callback(results);
      } else {
        callback([]);
      }
    },
    [getResults]
  );

  const noOptionMessage = useCallback(
    ({ inputValue }) => (inputValue ? "No options" : null),
    []
  );

  const loadingMessage = useCallback(() => null, []);

  const handleChange = useCallback(
    (selecteValue) => {
      if (selecteValue) {
        const [longitude, latitude] = selecteValue.value;
        currentMap.setCenter(
          { lng: longitude, lat: latitude },
          { search: true }
        );
      }
    },
    [currentMap]
  );

  const formatOptionLabel = useCallback((data, meta) => {
    if (meta.context === "menu") {
      return <Option currentLabel={data.label} inputValue={meta.inputValue} />;
    }

    return data.label;
  }, []);

  return (
    <div className="search-bar-container">
      <AsyncSelect
        placeholder="Search"
        cacheOptions
        loadOptions={loadOptions}
        isClearable
        noOptionsMessage={noOptionMessage}
        loadingMessage={loadingMessage}
        components={{ DropdownIndicator: null }}
        onChange={handleChange}
        classNamePrefix="search-bar-select"
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  );
};

export default SearchBar;
