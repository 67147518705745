import { useState, useEffect, useCallback } from "react";

import { useMountedState } from "./useMountedState";
import { getCurrentCoordinatesByIp } from "../services";

export const useGeolocation = (
  initialCoords = { longitude: null, latitude: null },
  options
) => {
  const isMounted = useMountedState();

  const [state, setState] = useState({
    loading: true,
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
    timestamp: Date.now(),
    ...initialCoords,
  });

  const getGeolocationByIp = useCallback(async () => {
    const dataByIp = await getCurrentCoordinatesByIp();

    return dataByIp.data.data;
  }, []);

  const onEvent = useCallback((event) => {
    setState({
      loading: false,
      accuracy: event.coords.accuracy,
      altitude: event.coords.altitude,
      altitudeAccuracy: event.coords.altitudeAccuracy,
      heading: event.coords.heading,
      latitude: event.coords.latitude,
      longitude: event.coords.longitude,
      speed: event.coords.speed,
      timestamp: event.timestamp,
    });
  }, []);

  const onEventError = useCallback(async () => {
    const coordByIp = await getGeolocationByIp();
    setState((oldState) => ({ ...oldState, loading: false, ...coordByIp }));
  }, [getGeolocationByIp]);

  useEffect(() => {
    if (isMounted()) {
      navigator.geolocation.getCurrentPosition(onEvent, onEventError, options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  return state;
};
