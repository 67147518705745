import { useState, useCallback, useEffect } from "react";
import {
  getCredentialsForIdentityPool,
  createRequestTransformer,
} from "amazon-location-helpers";
import Location from "aws-sdk/clients/location";

export const useAws = (identity, region) => {
  const [client, setClient] = useState(null);
  const [transformRequest, setRequestTransformer] = useState(null);

  const fetchCredentials = useCallback(async () => {
    const currentCredentials =
      await getCredentialsForIdentityPool(identity);

    return currentCredentials;
  }, [identity]);

  const createClient = useCallback((currentCredentials) => {
    const currentClient = new Location({
      credentials: currentCredentials,
      region,
    });

    setClient(currentClient);
  }, [region]);

  const makeRequestTransformer = useCallback(async (currentCredentials) => {
    const tr = await createRequestTransformer({
      credentials: currentCredentials,
      region,
    });

    setRequestTransformer(() => tr);
  }, [region]);

  const initialize = useCallback(
    async () => {
      const currentCredentials = await fetchCredentials();
      createClient(currentCredentials);
      await makeRequestTransformer(currentCredentials);
    },
    [createClient, fetchCredentials, makeRequestTransformer],
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  return [client, transformRequest]
};
