import { useRef, useCallback, useState, useEffect } from "react";

import { getInfoStoreByCode } from "../services";

export const usePopupInfo = () => {
  const infoCache = useRef({});
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentInfo, setCurrentInfo] = useState(null);
  const [currentCoords, setCurrentCoords] = useState(null);
  const [currentMarker, setCurrentMarker] = useState(null);
  const previousMarker = useRef(currentMarker);

  useEffect(() => {
    previousMarker.current = currentMarker;
  });

  const toggleOpenMenu = useCallback(() => {
    setOpenPopup((prev) => !prev);
  }, []);

  const addInfoToCache = useCallback((code, info) => {
    infoCache.current = { ...infoCache.current, [code]: info };
  }, []);

  const fetchPopupInfo = useCallback(async (code) => {
    setLoading(true);

    const {
      data: { data: infoStore },
    } = await getInfoStoreByCode(code);

    const parseInfo = {
      kind: infoStore.kind,
      address: infoStore.address,
      title: infoStore.name,
      description: infoStore.description,
      attributes: infoStore.json_data?.attributes,
      socialNetworks: infoStore.json_data?.social_networks,
      paymentUrl: infoStore.payment_url
    };

    addInfoToCache(code, parseInfo);

    setCurrentInfo(parseInfo);

    setLoading(false);
  }, [addInfoToCache]);

  const handleOpenPopup = useCallback(
    async (code, coords, marker) => {
      toggleOpenMenu();
      setCurrentCoords(coords);

      setCurrentMarker(marker);

      const mark = marker.getElement();
      const prevMark = previousMarker.current?.getElement();

      mark.style.zIndex =  99;

      if (prevMark && mark !== prevMark) {
        prevMark.style.zIndex = '';
      }

      const currentInf = infoCache.current[code];

      if (!currentInf) {
        await fetchPopupInfo(code);
      } else {
        setCurrentInfo(currentInf);
      }
    },
    [toggleOpenMenu, fetchPopupInfo]
  );

  const handleClosePopup = useCallback(() => {
    currentMarker.getElement().style.zIndex = '';
    setTimeout(() => {
      setCurrentMarker(null);
    }, 500);
    
    toggleOpenMenu();
    setCurrentCoords(null);
    setCurrentInfo(null);
  }, [toggleOpenMenu, currentMarker]);

  useEffect(() => {
    return () => {
      infoCache.current = {};
    };
  }, [])


  return {
    openPopup,
    handleOpenPopup,
    handleClosePopup,
    loading,
    cache: infoCache.current,
    currentInfo,
    currentCoords,
  };
};
