import React, {
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from "react";
import ReactMapGL, {
  NavigationControl,
  Popup as WrappedPopup,
  Marker,
} from "react-map-gl";
import maplibregl from "maplibre-gl";

import Popup from "../Popup";

import { getStoresNearby } from "../../services";
import { useQueryParams, useMarkerInMap, usePopupInfo } from "../../hooks";
import { MAP_STYLE } from "../../utils/env";

import "maplibre-gl/dist/maplibre-gl.css";

const Map = ({
  transformRequest,
  currentLocationUser: { longitude, latitude },
}) => {
  const mapRef = useRef(null);
  const { lon: lonQueryParams, lat: latQueryParams } = useQueryParams();

  const { addMarker } = useMarkerInMap(mapRef, maplibregl);
  const {
    loading: loadingFetchPopup,
    currentInfo: currentInfoPopup,
    currentCoords: currentCoordsPopup,
    handleOpenPopup,
    handleClosePopup,
  } = usePopupInfo();

  const fetchStoresNearby = useCallback(
    async (lng, lat) => {
      const {
        data: { data: storesNearby },
      } = await getStoresNearby(lng, lat);

      storesNearby.forEach((store) => {
        const elMarker = document.createElement("div");
        elMarker.className = "custom-marker";

        addMarker(
          { element: elMarker },
          {
            longitude: Number(store.longitude),
            latitude: Number(store.latitude),
          },
          (marker, ev) => {
            ev.stopPropagation();
            handleOpenPopup(
              store.code,
              {
                longitude: Number(store.longitude),
                latitude: Number(store.latitude),
              },
              marker
            );
          }
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addMarker]
  );

  const [longitudeBySearch, setLongitudeBySearch] = useState(null);
  const [latitudeBySearch, setLatitudeBySearch] = useState(null);

  const currentLongitude = useMemo(
    () => Number(longitudeBySearch ?? lonQueryParams ?? longitude),
    [longitudeBySearch, lonQueryParams, longitude]
  );
  const currentLatitude = useMemo(
    () => Number(latitudeBySearch ?? latQueryParams ?? latitude),
    [latitudeBySearch, latQueryParams, latitude]
  );

  useEffect(() => {
    if (currentLongitude && currentLatitude) {
      mapRef.current?.setCenter({
        lng: currentLongitude,
        lat: currentLatitude,
      });

      fetchStoresNearby(currentLongitude, currentLatitude);
    }
  }, [currentLongitude, currentLatitude, fetchStoresNearby]);

  const handleDragEnd = useCallback(
    async (event) => {
      await fetchStoresNearby(
        event.viewState.longitude,
        event.viewState.latitude
      );
    },
    [fetchStoresNearby]
  );

  const handleMoveBySearch = useCallback(
    async (event) => {
      if (event.search) {
        setLongitudeBySearch(event.viewState.longitude);
        setLatitudeBySearch(event.viewState.latitude);

        await fetchStoresNearby(
          event.viewState.longitude,
          event.viewState.latitude
        );
      }
    },
    [fetchStoresNearby]
  );

  return (
    <ReactMapGL
      ref={mapRef}
      mapLib={maplibregl}
      transformRequest={transformRequest}
      mapStyle={MAP_STYLE}
      initialViewState={{
        longitude: currentLongitude,
        latitude: currentLatitude,
        zoom: 14,
      }}
      minZoom={10}
      maxZoom={20}
      onDragEnd={handleDragEnd}
      onMove={handleMoveBySearch}
      attributionControl={false}
    >
      <NavigationControl showCompass={false} />

      <Marker
        longitude={currentLongitude}
        latitude={currentLatitude}
        color="red"
      />

      {currentCoordsPopup && (
        <WrappedPopup
          offset={[25, 0]}
          onClose={() => handleClosePopup()}
          closeButton={false}
          anchor="bottom-right"
          {...currentCoordsPopup}
        >
          <Popup {...currentInfoPopup} loading={loadingFetchPopup} />
        </WrappedPopup>
      )}
    </ReactMapGL>
  );
};

export default Map;
