import { useCallback } from "react";

export const useMarkerInMap = (mapRef, mapLib) => {
  const addMarker = useCallback(
    (options, { longitude, latitude }, handleClick) => {
      if (mapRef.current) {
        const marker = new mapLib.Marker(options)
          .setLngLat([longitude, latitude])
          .addTo(mapRef.current.getMap());

        marker.getElement().addEventListener("click", evt => {
          handleClick && handleClick(marker, evt);
        });
      }
    },
    [mapRef, mapLib]
  );

  return { addMarker };
};
